@import "../../App.scss";

.swiper {
  .swiper-slide {
    position: relative;
    overflow: hidden;
    display: flex;
    .movie-poster-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
    .movie-infos {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -50px;
      opacity: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.75rem;
      padding: 0 0.5rem 1rem 0.5rem;
      background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
      transition: all 0.25s;
      .swiper-title {
        font-weight: 500;
        font-size: 1rem;
        line-height: normal;
        @include truncate(2);
      }
      .swiper-vote {
        font-size: 0.7rem;
        .imdb {
          background-color: $first-color;
          color: $dark-color;
          font-size: 0.6rem;
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
        }
        .vote {
          padding-left: 0.25rem;
        }
      }
      .swiper-overview {
        font-size: 0.7rem;
        @include truncate(2);
      }
    }
    &:hover {
      .movie-infos {
        bottom: 0;
        opacity: 1;
      }
    }
  }
  .swiper-button-prev {
    top: 0;
    left: 0;
    margin: 0;
    width: 50px;
    height: 100%;
    color: $text-color;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.67),
      rgba(0, 0, 0, 0.2)
    );
    opacity: 0;
    transition: all 0.25s;
    &:hover {
      opacity: 1;
    }
  }
  .swiper-button-next {
    top: 0;
    right: 0;
    margin: 0;
    width: 50px;
    height: 100%;
    color: $text-color;
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.67),
      rgba(0, 0, 0, 0.2)
    );
    opacity: 0;
    transition: all 0.25s;
    &:hover {
      opacity: 1;
    }
  }
}
