@import "../../App.scss";

.main-hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
}
.main-hero {
  position: relative;
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    width: 100%;
    height: 30%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    pointer-events: none;
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  }
  .hero-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.6);
  }
  .hero-infos {
    position: relative;
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    &.center {
      justify-content: center;
    }
    gap: 0.75rem;
    @include tablet {
      padding: 0 2rem;
      width: 75%;
    }
    @include mobile {
      width: 100%;
    }
    .hero-title {
      font-weight: 700;
      font-size: 3rem;
      line-height: normal;
      @include truncate(2);
    }
    .hero-genres {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      .genre-box {
        padding: 0.25rem 0.75rem;
        border-radius: 0.25rem;
        font-size: 0.9rem;
        background-color: rgba(0, 0, 0, 0.624);
        .bi {
          color: $first-color;
          padding-right: 0.25rem;
        }
      }
    }
    .hero-vote {
      font-size: 0.9rem;
      .imdb {
        background-color: $first-color;
        color: $dark-color;
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
      }
      .vote {
        padding-left: 0.5rem;
      }
    }
    .hero-overview {
      font-size: 1rem;
      @include truncate(3);
    }
    .hero-link {
      position: relative;
      width: max-content;
      display: flex;
      gap: 0.6rem;
      align-items: center;
      padding: 0.75rem 1.25rem;
      overflow: hidden;
      color: $text-color;
      font-size: 0.9rem;
      border-radius: 0.5rem;
      border: 1px solid $first-color;
      background-color: rgba(0, 0, 0, 0.624);
      .bi {
        color: $first-color;
      }
      .arrow {
        position: relative;
        margin-left: -1rem;
        opacity: 0;
        transition: 0.25s;
      }
      &:hover {
        .arrow {
          margin-left: 0;
          opacity: 1;
        }
      }
    }
  }
}
