@import "../../App.scss";
$nav-width: 230px;

.header {
  position: fixed;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  left: 0;
  top: 0;
  width: 100%;
  height: $header-height;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
  z-index: 9;
  @include mobile {
    padding: 0 1rem;
    justify-content: space-between;
  }
  .menu-btn {
    color: $text-color;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    display: none;
    cursor: pointer;
    @include mobile {
      display: block;
    }
  }
  .logo {
    width: 128px;
    object-fit: cover;
  }
  .navbar {
    width: 600px;
    max-width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: all 0.25s ease-in-out;
    .mobile-dark-section {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: -1;
      opacity: 0;
      pointer-events: none;
      transition: all 0.25s ease-in-out;
    }
    @include mobile {
      position: fixed;
      width: $nav-width;
      max-width: 100%;
      padding: 1rem 2rem;
      top: 0;
      left: -$nav-width;
      height: 100vh;
      overflow: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background-color: black;
      background: linear-gradient(to right, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0));
      z-index: 9;
      &.active {
        left: 0;
        .mobile-dark-section {
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
    .nav-logo {
      margin: 2rem 0;
      display: none;
      @include mobile {
        display: block;
      }
    }
    .nav-link {
      padding: 0.5rem 1rem;
      transition: all 0.25s;
      &:hover,
      &.active {
        color: $first-color;
      }
      @include mobile {
        padding: 0.5rem 0;
      }
      .bi {
        display: none;
        padding-right: 1rem;
        @include mobile {
          display: inline-block;
        }
      }
    }
  }
  .register-link {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    color: $text-color;
    border-radius: 0.75rem;
    transition: all 0.25s;
    &:hover {
      color: $first-color;
    }
    .bi {
      color: $first-color;
      padding-right: 0.5rem;
    }
  }
}
