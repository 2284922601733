@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

$first-color: #f5c518;

$text-color: #f6f2f2;
$dark-color: #000;

$main-bg: #000;

$font: "Montserrat", sans-serif;
$mobile-width: 620px;
$tablet-width: 1024px;
$header-height: 80px;

@mixin truncate($line) {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin overlay {
  background-color: rgba($color: #000000, $alpha: 0.6);
}

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
  @include tablet {
    font-size: 14px;
  }
}
body {
  min-height: 100vh;
  font-family: $font;
  font-weight: 400;
  line-height: 1.5;
  background-color: $main-bg;
  color: $text-color;

  @include mobile {
    margin-bottom: 3rem;
  }
}
a {
  text-decoration: none;
  color: unset;
}
button,
input {
  outline: 0;
}
ul {
  list-style-type: none;
}
img {
  max-width: 100%;
}
.container {
  position: relative;
  width: $tablet-width;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
}
.section {
  padding: 3rem 1rem 2rem 1rem;
  .section-title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
    .title {
      color: $text-color;
      font-size: 2rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      text-transform: capitalize;
      .bi {
        color: $first-color;
      }
    }
    .line {
      width: 75px;
      height: 4px;
      background-color: $first-color;
      border-radius: 0.5rem;
    }
  }
}
.loader-container {
  width: 100%;
  height: 400px;
  display: grid;
  place-items: center;
  .loader {
    position: relative;
    display: flex;

    &:before,
    &:after {
      content: "";
      width: 15px;
      height: 15px;
      display: inline-block;
      position: relative;
      margin: 0 5px;
      border-radius: 50%;
      color: #fff;
      background: currentColor;
      box-shadow: 50px 0, -50px 0;
      animation: left 1s infinite ease-in-out;
    }

    &:after {
      color: $first-color;
      animation: right 1.1s infinite ease-in-out;
    }
    @keyframes right {
      0%,
      100% {
        transform: translateY(-10px);
      }
      50% {
        transform: translateY(10px);
      }
    }

    @keyframes left {
      0%,
      100% {
        transform: translateY(10px);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  }
}
