@import "../../App.scss";

.movie-boxes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  @include tablet {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }
  .movie-box {
    width: 100%;
    height: 100%;
    position: relative;
    .movie-box-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }
    .movie-infos {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -50px;
      opacity: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.75rem;
      padding: 0 0.5rem 1rem 0.5rem;
      background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
      transition: all 0.25s;
      .movie-title {
        font-weight: 500;
        font-size: 1rem;
        line-height: normal;
        @include truncate(2);
      }
      .movie-vote {
        font-size: 0.7rem;
        .imdb {
          background-color: $first-color;
          color: $dark-color;
          font-size: 0.6rem;
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
        }
        .vote {
          padding-left: 0.25rem;
        }
      }
      .movie-overview {
        font-size: 0.7rem;
        @include truncate(2);
      }
    }
    &:hover {
      .movie-infos {
        bottom: 0;
        opacity: 1;
      }
    }
  }
}
